//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState} from 'react';//Header 컴포넌트를 가져온 것이고
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_construction_point_use,
  get_construction_point_big_area,
} from "../../Axios";
//카카오 패키지 가져오기 자세한건 KakaoMap.js확인
import KakaoMap from "../../KakaoMap";
import kakaomapPopupLinkKaKaomap from '../../../src/Images/Etc/kakaomapPopupLinkKaKaomap.jpg';

function OCPoint() {
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [constructionPointData, setConstructionPointData] = useState([]); //백엔드에서 가져온 시공점 데이터를 담기위한 useState

  //컨트롤 데이터
  // constructionPointBigAreaSelect는 검색조건을 활용하기 위해 만든 useState Hook
  const [constructionPointBigAreaSelect, setConstructionPointBigAreaSelect] = useState('');
  // 지역 검색조건 값
  const [constructionPointBigArea, setConstructionPointBigArea] = useState('');
  // input 검색조건 값
  const [constructionPointSearchInput, setConstructionPointSearchInput] = useState('');
  // 마스터샵, 프로샵, 취급점 클릭 값
  //const [constructionPointDivision, setConstructionPointDivision] = useState(0);
  const constructionPointDivision = 0;

  // 기존에 클릭했던 지도 클릭 인덱스
  const [clickIndex, setClickIndex]  = useState(0);

  //Render Data
  //페이지를 한번 다시 읽는 개념이 render인데 useState를 활용하여 강제 렌더링 시킴
  const [render, setRender] = useState(false);

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 시공점 지역 데이터들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_construction_point_big_area().then(result_data=>{
      //시공점 지역 데이터를 setConstructionPointBigAreaSelect를 활용하여 constructionPointBigAreaSelect에 할당
      setConstructionPointBigAreaSelect(result_data);
    });
  },[]);

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 시공점 데이터들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_construction_point_use(
      constructionPointBigArea,
      constructionPointSearchInput,
      constructionPointDivision
    ).then(result_data=>{
      //시공점 데이터를 setConstructionPointData를 활용하여 constructionPointData에 할당
      setConstructionPointData(result_data);
      //만약에 시공점 데이터를 어떻게 가져왔나를 확인하고 싶으면
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
    });
    //useEffect 하단 배열안에 값을 넣으면 값이 변경될 때마다 useEffect가 실행된다.
    //예를들어 constructionPointBigArea은 select박스의 값인데
    //그 값이 바뀌면 get_construction_point_use를 실행시켜 시공점 데이터들을 검색조건에 맞춰서 가져온다.
  },[constructionPointBigArea, constructionPointSearchInput, constructionPointDivision]);

  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>

      <H.mapSearchWrap>
      
        <H.mapSearchListWrap>
          <H.mapSearchListBox>
            <h2>오토반 시공점 찾기</h2>
            <select
              value={constructionPointBigArea}
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setConstructionPointBigArea(event_data.target.value);
              }}
            >
              <option value=''>전체</option>
              {/* map함수에 대한 설명은 src/Homepage/Main/Main.js 참조 */}
              {constructionPointBigAreaSelect !== '' && constructionPointBigAreaSelect.map((element, index) => 
              <option
                key={index}
                value={element.construction_point_big_area}  
              >
                {element.construction_point_big_area}
              </option>
              )}
            </select>
            <input
              type='text' 
              maxLength={30} 
              //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
              onChange={(event_data)=>{
                setConstructionPointSearchInput(event_data.target.value);
              }} 
              placeholder = "찾으실 내용을 입력해주세요."
              value={constructionPointSearchInput}
            />
          </H.mapSearchListBox>

          {/* 마스터샵, 프로샵, 취급점 구분 */}
         {/*  <H.mapSearchListChoiceBox>
            <div 
              //클릭 상태에 따라 className변경
              className={constructionPointDivision === 0 ? 'click' : 'nonClick'}
              onClick={()=>{setConstructionPointDivision(0)}}
            >전체
            </div>
            <div 
              //클릭 상태에 따라 className변경
              className={constructionPointDivision === 1 ? 'click' : 'nonClick'}
              onClick={()=>{setConstructionPointDivision(1)}}
            >마스터샵
            </div>
            <div 
              //클릭 상태에 따라 className변경
              className={constructionPointDivision === 2 ? 'click' : 'nonClick'}
              onClick={()=>{setConstructionPointDivision(2)}}
            >프로샵
            </div>
            <div 
              //클릭 상태에 따라 className변경
              className={constructionPointDivision === 3 ? 'click' : 'nonClick'}
              onClick={()=>{setConstructionPointDivision(3)}}
            >취급점
            </div>
            <div 
              //클릭 상태에 따라 className변경
              className={constructionPointDivision === 4 ? 'click' : 'nonClick'}
              onClick={()=>{setConstructionPointDivision(4)}}
            >건축필름
            </div>
          </H.mapSearchListChoiceBox> */}

          <H.mapSearchListChoiceListBox>
            {/* map함수에 대한 설명은 src/Homepage/Main/Main.js 참조 */}
            {constructionPointData !== '' && constructionPointData.map((element, index) => 
            <H.mapSearchListChoiceList
              key={index}
              //클릭 시 백엔드에서 clickStatus 값을 false로 넘겨주는데 클릭 시 !not 연산자를 사용해서 반대값으로 
              //clickStatus바꿔버리고 다시 setConstructionPointData useState Hook을 사용하여 값을 변경
              //값이변경되면 setRender를 통해서 랜더링 시킴
              onClick={()=>{
                if(clickIndex !== index){
                  constructionPointData[clickIndex].clickStatus = false;
                  setClickIndex(index);
                }
                constructionPointData[index].clickStatus = !constructionPointData[index].clickStatus;
                setConstructionPointData(constructionPointData);
                setRender(!render);
              }}
            >
              <p className="mapChoiceTit">
                <span>{index + 1}</span>
                &nbsp;&nbsp;{element.construction_point_name}
              </p>
              <div
                //클릭 상태에 따라 className변경
                className={constructionPointData[index].clickStatus === true ? '' : 'display_none'}
              >
                <div>
                  <p className="mapChoiceInfoTit">주소</p>
                  <p>{element.construction_point_road_address} {element.construction_point_detail_address}</p>
                </div>
                <div>
                  <p className="mapChoiceInfoTit">대표번호</p>
                  <p>{element.construction_point_phone_number}</p>
                </div>
                {element.construction_point_kakao_url !== '' && 
                <div className = "kakaomapPopup_LinkBox">
                  <a className = "kakaomapPopup_Link01" href={element.construction_point_kakao_url} target="_blank" rel="noreferrer">
                    <img src ={kakaomapPopupLinkKaKaomap} alt="카카오맵 바로가기"/>
                  </a>
                </div>
                }
              </div>
            </H.mapSearchListChoiceList>
            )}
          </H.mapSearchListChoiceListBox>

        </H.mapSearchListWrap>

        <H.mapSearchMapWrap>
          {/* 카카오 맵을 활용하기 위한 div 
          스타일링 잡으려면 KakaoMap에 들어가셔 잡으셔야 합니다.*/}
          <KakaoMap 
            constructionPointData={constructionPointData} 
          />
        </H.mapSearchMapWrap>

      </H.mapSearchWrap>

      <Footer/>
    </>
  );
}

export default OCPoint;
