//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_menu_permission,
  get_menu_permission_one,
  update_menu_permission,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function MenuPermissionManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 MenuPermissionManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/MenuPermissionManagementAdd?menu_permission_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //menu_permission_ID=2를 쓸 수 있다.
  //즉 menu_permission_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const menu_permission_ID_param = params.get("menu_permission_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [menuPermissionName, setMenuPermissionName] = useState('');
  const [menuPermissionArray, setMenuPermissionArray] = useState([]);
  const [menuPermissionStatus, setMenuPermissionStatus] = useState('1');

  //에러 출력 데이터
  const [menuPermissionNameError, setMenuPermissionNameError] = useState('');

  //에러 출력 데이터
  const [render, renderStatus] = useState(false);

  //컨트롤 함수
  const menu_permission_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(menuPermissionName === ''){setMenuPermissionNameError('메뉴권한명을 등록해 주세요.'); continue_add = false;}else{setMenuPermissionNameError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(menu_permission_ID_param === null){
        insert_menu_permission(
          menuPermissionName,
          menuPermissionStatus,
          JSON.stringify(menuPermissionArray)
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/MenuPermissionManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_menu_permission(
          menu_permission_ID_param,
          menuPermissionName,
          menuPermissionStatus,
          JSON.stringify(menuPermissionArray)
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/MenuPermissionManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  const insert_menu_permission_check_box = (event, big_menu_ID, menu_ID) =>{
    if(event.target.checked === true){
      if(!check_box_bool_judgement(big_menu_ID)){
        menuPermissionArray.push(big_menu_ID);
      }
      menuPermissionArray.push(menu_ID);
    }else{
      for(let i = 0; i < menuPermissionArray.length; i++) {
        if(menuPermissionArray[i] === menu_ID)  {
          menuPermissionArray.splice(i, 1);
          i--;
        }
      }
    }
    setMenuPermissionArray(menuPermissionArray);
    renderStatus(!render);
  }

  const insert_menu_permission_check_box_all = (event, menu_ID) =>{
    menu_ID.forEach(element => {
      if(event.target.checked === true){
        if(!check_box_bool_judgement(element)){
          menuPermissionArray.push(element);
        }
      }else{
        for(let i = 0; i < menuPermissionArray.length; i++) {
          if(menuPermissionArray[i] === element)  {
            menuPermissionArray.splice(i, 1);
            i--;
          }
        }
      }
    });
    setMenuPermissionArray(menuPermissionArray);
    renderStatus(!render);
  }

  const check_box_bool_judgement  = (menu_ID) =>{
    let bool = false;
    for(let i = 0; i < menuPermissionArray.length; i++) {
      if(menuPermissionArray[i] === menu_ID)  {
        bool = true;
      }
    }
    return bool;
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 menu_permission_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(menu_permission_ID_param !== null){
      //특정 ID의 메뉴권한 데이터를 가져오기 위한 함수
      get_menu_permission_one(menu_permission_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setMenuPermissionArray(JSON.parse(result[0].menu_permission_array));
        setMenuPermissionName(result[0].menu_permission_name);
        setMenuPermissionStatus(result[0].menu_permission_status);
      })
    }
  },[menu_permission_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>시스템 관리 - 메뉴권한 {menu_permission_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  menu_permission_check();
                }}
              >
                {/* 메뉴권한 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {menu_permission_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='menu_permission_name'>메뉴권한명<span> * </span></label>
              <input 
                type='text' 
                id = 'menu_permission_name' 
                maxLength={50} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setMenuPermissionName(event_data.target.value);
                }} 
                placeholder = "메뉴권한명을 입력해 주세요."
                value={menuPermissionName}
              />
              <H.admin_errorBox className="marginLeft150">{menuPermissionNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}
            {menu_permission_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='menu_permission_status'>사용 여부</label>
              <select
                value={menuPermissionStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setMenuPermissionStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['1', '1-1', '1-2']);
                  }}
                  checked={check_box_bool_judgement('1')}
                />
                <label htmlFor='checkbox_menu_1'>시스템 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '1', '1-1');
                  }}
                  checked={check_box_bool_judgement('1-1')}
                />
                <label htmlFor='checkbox_menu_1_1'>메뉴권한 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_1_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '1', '1-2');
                  }}
                  checked={check_box_bool_judgement('1-2')}
                />
                <label htmlFor='checkbox_menu_1_2'>사용자 관리</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_7' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['7', '7-1', '7-2']);
                  }}
                  checked={check_box_bool_judgement('7')}
                />
                <label htmlFor='checkbox_menu_7'>게시판 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_7_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '7', '7-1');
                  }}
                  checked={check_box_bool_judgement('7-1')}
                />
                <label htmlFor='checkbox_menu_7_1'>공지사항 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_7_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '7', '7-2');
                  }}
                  checked={check_box_bool_judgement('7-2')}
                />
                <label htmlFor='checkbox_menu_7_2'>자료실 관리</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['2', '2-1', '2-2', '2-3']);
                  }}
                  checked={check_box_bool_judgement('2')}
                />
                <label htmlFor='checkbox_menu_2'>메인화면 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-1');
                  }}
                  checked={check_box_bool_judgement('2-1')}
                />
                <label htmlFor='checkbox_menu_2_1'>롤링 배너</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-2');
                  }}
                  checked={check_box_bool_judgement('2-2')}
                />
                <label htmlFor='checkbox_menu_2_2'>오토반 썬팅필름 배너</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_2_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '2', '2-3');
                  }}
                  checked={check_box_bool_judgement('2-3')}
                />
                <label htmlFor='checkbox_menu_2_3'>대형 이미지 배너</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['3', '3-1', '3-2', '3-3']);
                  }}
                  checked={check_box_bool_judgement('3')}
                />
                <label htmlFor='checkbox_menu_3'>메뉴 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '3', '3-1');
                  }}
                  checked={check_box_bool_judgement('3-1')}
                />
                <label htmlFor='checkbox_menu_3_1'>오토반 썬팅필름</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '3', '3-2');
                  }}
                  checked={check_box_bool_judgement('3-2')}
                />
                <label htmlFor='checkbox_menu_3_2'>가격/스펙</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_3_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '3', '3-3');
                  }}
                  checked={check_box_bool_judgement('3-3')}
                />
                <label htmlFor='checkbox_menu_3_3'>뉴스 & 이벤트</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_5' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['5', '5-1']);
                  }}
                  checked={check_box_bool_judgement('5')}
                />
                <label htmlFor='checkbox_menu_5'>시공점 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_5_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '5', '5-1');
                  }}
                  checked={check_box_bool_judgement('5-1')}
                />
                <label htmlFor='checkbox_menu_5_1'>시공점</label>
              </div>
            </H.admin_menuPermissionBox>

            <H.admin_menuPermissionBox>
              <div>
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_6' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box_all(e, ['6', '6-1', '6-2', '6-3']);
                  }}
                  checked={check_box_bool_judgement('6')}
                />
                <label htmlFor='checkbox_menu_6'>보증서 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_6_1' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '6', '6-1');
                  }}
                  checked={check_box_bool_judgement('6-1')}
                />
                <label htmlFor='checkbox_menu_6_1'>보증서 관리</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_6_2' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '6', '6-2');
                  }}
                  checked={check_box_bool_judgement('6-2')}
                />
                <label htmlFor='checkbox_menu_6_2'>오토반 썬팅필름 종류</label>
              </div>
              <div className="admin_menuPermissionSub">
                <em></em>
                <input 
                  type='checkbox'
                  id = 'checkbox_menu_6_3' 
                  onChange={(e)=>{
                    insert_menu_permission_check_box(e, '6', '6-3');
                  }}
                  checked={check_box_bool_judgement('6-3')}
                />
                <label htmlFor='checkbox_menu_6_3'>보증기간</label>
              </div>
            </H.admin_menuPermissionBox>
            
            
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default MenuPermissionManagementAdd;
