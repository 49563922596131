//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../../Styles/StyleComponentHan';

//아래 함수들에 대한 자세한 설명은 Axios.js 파일을 참고하세요
import {
  get_construction_point,
  update_construction_point_order,
} from "../../../Axios";

export function AdminOCPointManagement() {  
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [constructionPointData, setConstructionPointData] = useState([]); //백엔드에서 가져온 시공점 데이터를 담기위한 useState

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

  //여기는 tabulator에서 이벤트 발생 시 실행하는 함수들의 모임
  //아래 함수는 로우가 움직일때 실행되는 함수
  const row_moved_function = (e, row) => {  
    //백엔드에 움직인 순서대로 처리하기 위해 함
    //일단 빈공간의 배열을 만든다.
    let temp_construction_point_order_array = [];
    //처음 순서는 0으로 시작한다.
    let construction_point_order= 0;

    //tabulator에서 받은 데이터를 활용하여 forEach를 사용한다.
    //forEach는 배열안에 있는 객체를 돌아가면서 활용할 수 있다.
    //자세한 거는 아래에 있는
    //console.log(tabulatorRef.current.optionsList.table.rowManager.activeRows);
    //console.log(element); 주석을 풀고 F12눌러서 콘솔창에서 확인하면 알 수 있을 거다.
    //console.log(tabulatorRef.current.optionsList.table.rowManager.activeRows);
    tabulatorRef.current.optionsList.table.rowManager.activeRows.forEach(element =>{
      //console.log(element);
      //temp_construction_point_order_array.push는 배열안에 값을 넣을 수 있는 함수인데
      //자세한건 인터넷에 찾아보면 나온다 :)
      //쉽게 이야기하면 []빈공간 배열안에 table의 데이터를 forEach를 돌려서 나온 각각의 값을
      //배열안에 집어 넣는거다.
      temp_construction_point_order_array.push({
        construction_point_order: construction_point_order,
        construction_point_ID: element.data.construction_point_ID,
      })
      //++;는 construction_point_order = construction_point_order + 1; 같은 효과인데
      //construction_point_order가 처음에는 0으로 시작했고 forEach를 실행하면서
      //배열안에 있는 객체들을 하나하나 실행하는데 그 실행이 끝나는 시점에서 +1을 시킨다 보면 된다.
      construction_point_order++;
    });
    //update_construction_point_order는 Axios.js에서 가져온 API함수이고
    //위에서 처리한 리스트 순서값을 보내서 리스트 순서를 최신화 시키는거다.
    update_construction_point_order(temp_construction_point_order_array).then(result=>{
      //result === undefined 이라는 것은 백엔드에서 update 처리가 안될시 결과가 undefiend로 결과가 오는데 
      //그때 alert을 출력합니다. 
      if(result === undefined){
        alert('순서가 변경되지 않았습니다. 관리자한테 확인해주세요.');
      }
    })    
  };

  //이거는 수정버튼 눌렀을 때 체크하는 함수 입니다.
  const check_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      //이건 GET방식으로 URL을 이동하는 것인데
      //?뒤에 데이터를 포함시켜 이동시킬 수 있다.
      //http://localhost:3000/AdminOCPointManagementAdd?construction_point_ID=2
      //이렇게 오토반 시공점의 고유 ID를 포함시켜서 날리는데
      //그럼 AdminOCPointManagementAdd여기에서 construction_point_ID 2라는 것을 활용할 수 있다.
      window.location.href = `/AdminOCPointManagementAdd?construction_point_ID=${tabulatorRef.current.getSelectedData()[0].construction_point_ID}`;
    }
  }

  //엑셀 다운로드
  const download_excel = () => {
    tabulatorRef.current.download("xlsx", "시공점.xlsx", {
      sheetName: "시공점"
    });
  }; 

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 시공점들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_construction_point().then(result_data=>{
      //시공점 데이터를 setConstructionPointData를 활용하여 constructionPointData에 할당
      setConstructionPointData(result_data);
      //만약에 시공점 데이터를 어떻게 가져왔나를 확인하고 싶으면
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>시공점 관리 - 시공점</p>
            <div>
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{window.location.href = "/AdminOCPointManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_edit();}}>수정
              </H.admin_changeBtn>
              <H.admin_deleteBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{download_excel();}}>엑셀 다운
              </H.admin_deleteBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>


        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 리스트 중 상단이 정렬 우선순위가 높습니다.</p>
              <p>2. 왼쪽 3선 작대기를 클릭 ➡️ 드래그하여 위/아래로 이동할 수 있습니다.</p>
              <p>3. 카카오맵 및 패스워드 수정은 우측 상단 수정 버튼을 통해 변경할 수 있습니다.</p>
              <p>4. 삭제는 안되며 수정 버튼을 통해 사용여부를 미사용으로 활용하시면 됩니다.</p>
            </H.listInfoText>
            {/* 이것도 리액트의 꽃인데
            constructionPointData 이게 useState를 통해서 기본값을 []으로 잡았잖아요
            즉 constructionPointData.length라는 것은 만약에 DB에서 데이터를 가져오지 않았다면 계속 빈 배열이고
            만약 2개의 데이터를 가져왔다면
            [
              {construction_point_ID : 1},
              {construction_point_ID : 2},
            ]
            위처럼 2개의 데이터를 가져오게 되는데
            아래 constructionPointData.length라는 함수는
            저 배열안에 객체(객체라고 하면 {}이안에 들어가는 데이터에요)의 수를 판단해주거든요
            그래서 3항연산자를 활용해서
            constructionPointData.length !== 0 ? 이말은
            constructionPointData의 배열안에 객체의 갯수가 0이아니라면
            <ReactTabulator
              data={constructionPointData}
              columns={columns}
              layout={"fitData"}
            />
            tabulator를 출력하는거고
            갯수가 0이라면
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            이놈을 출력하게 되는거죠
            만약에 constructionPointData.length !== 0 이거를 constructionPointData.length === 0 으로 바꾸면
            거꾸로라서 데이터가 없습니다.를 CSS처리 할 수 있죠
            처리가 완료되면 다시 !==으로 바꾸고 
            */}
            {
            constructionPointData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={constructionPointData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                {rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "construction_point_ID", headerSort:false},
                { title: "구분", field: "construction_point_division", headerSort:false},
                { title: "시공점명", field: "construction_point_name", headerSort:false},
                { title: "대표자", field: "construction_point_representative", headerSort:false},
                { title: "대표번호", field: "construction_point_phone_number", headerSort:false},
                { title: "로그인ID", field: "construction_point_login_ID", headerSort:false},
                { title: "우편번호", field: "construction_point_zip_code", headerSort:false},
                { title: "도로명주소", field: "construction_point_road_address", headerSort:false},
                { title: "상세주소", field: "construction_point_detail_address", headerSort:false},
                { title: "광역단체", field: "construction_point_big_area", headerSort:false},
                { title: "기초단체", field: "construction_point_small_area", headerSort:false},
                { title: "사용 여부", field: "construction_point_status", headerSort:false},
                { title: "등록 일자", field: "construction_point_registe", headerSort:false},
                { title: "수정 일자", field: "construction_point_modified", headerSort:false},
              ]}
              layout={"fitData"}
              options={{
                //row를 움직일 수 있게
                movableRows: true,
              }}
              events={{
                //row가 움직이면 위에 선언한 row_moved_function을 실행
                rowMoved:row_moved_function
              }}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminOCPointManagement;
