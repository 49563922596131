//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import {
  get_fix_banner,
  base_url_export,
  update_fix_banner,
} from "../../../Axios";
import * as H from '../../../Styles/StyleComponentHan';
//이미지를 사용하고 싶을때 아래와 같이 이미지 경로를 입력하여 사용
import no_image from '../../../Images/Etc/no_image.gif';


export function OurStoryBannerManagement() {  

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [fixBannerImage, setFixBannerImage] = useState('');
  const [fixBannerImageMobie, setFixBannerImageMobie] = useState('');
  const [fixBannerTitle, setFixBannerTitle] = useState('');
  const [fixBannerLink, setFixBannerLink] = useState('');

  //컨트롤 데이터
  const [fixBannerImageURL, setFixBannerImageURL] = useState('');
  const [fixBannerImageMobieURL, setFixBannerImageMobieURL] = useState('');

  //에러 출력 데이터
  const [fixBannerImageError, setFixBannerImageError] = useState('');
  const [fixBannerImageMobieError, setFixBannerImageMobieError] = useState('');
  const [fixBannerTitleError, setFixBannerTitleError] = useState('');


  //컨트롤 함수
  const fix_banner_check = () =>{
    let continue_add = true;
    //여기는 수정버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(fixBannerImageURL === ''){setFixBannerImageError('배너 PC용 이미지를 등록해 주세요.'); continue_add = false;}else{setFixBannerImageError('');}
    if(fixBannerImageMobieURL === ''){setFixBannerImageMobieError('배너 모바일용 이미지를 등록해 주세요.'); continue_add = false;}else{setFixBannerImageMobieError('');}
    if(fixBannerTitle === ''){setFixBannerTitleError('배너타이틀을 등록해 주세요.'); continue_add = false;}else{setFixBannerTitleError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      update_fix_banner(
        '1',
        fixBannerImage,
        fixBannerImageMobie,
        fixBannerLink,
        fixBannerTitle,
      ).then(()=>{
        alert('수정이 완료되었습니다.');
        window.location.href = "/OurStoryBannerManagement";
      });
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    // '1'은 대형 이미지 배너입니다.
    get_fix_banner('1').then((result) => {
      //데이터를 가져오면 데이터를 집어넣어준다.
      setFixBannerLink(result[0].fix_banner_link);
      setFixBannerTitle(result[0].fix_banner_title);
      //여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서
      //export로 선언하여 사용하는 것이다.
      setFixBannerImageURL(base_url_export + result[0].fix_banner_image);
      setFixBannerImageMobieURL(base_url_export + result[0].fix_banner_image_mobile);
    })
  },[]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메인화면 관리 - 대형 이미지 배너 수정</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_changeBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  fix_banner_check();
                }}
              >
                수정
              </H.admin_changeBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>


          <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='fix_banner_title'>배너타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'fix_banner_title' 
                maxLength={50} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setFixBannerTitle(event_data.target.value);
                }} 
                placeholder = "배너타이틀을 입력해주세요."
                value={fixBannerTitle}
              />
              <H.admin_errorBox className="marginLeft150">{fixBannerTitleError}</H.admin_errorBox>
            </H.admin_labelBox>
            
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='fix_banner_link'>배너링크</label>
              <input 
                type='text' 
                id = 'fix_banner_link' 
                maxLength={300} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setFixBannerLink(event_data.target.value);
                }} 
                placeholder = "배너 클릭 시 이동을 원하는 링크 주소를 작성해 주세요. 없으면 생략 가능 합니다."
                value={fixBannerLink}
              />
            </H.admin_labelBox>
    
            <H.admin_labelImg>
              <p className="labelImgTit">배너이미지</p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>PC용 배너이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1120 x 600px</p>
                    <label htmlFor='fix_banner_image'>
                      {/* 배너 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                      변경
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'fix_banner_image'
                    //이건 input type='file'일 경우만 가능한건데
                    //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                    accept='image/gif, image/jpeg, image/png'
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      //if문을 사용하게 되는데 if문은 조건이 맞는지 안맞는지에 따라 아래 자바스크립트가 사용이 됩니다.
                      //아래 if문을 해석하면 event_data.target.files[0] !== undefiend 라는 것은
                      //사진파일이 정의되지 되지 않은게 아니라면 아래 자바스크립트를 실행해라 뜻입니다.
                      if(event_data.target.files[0] !== undefined){
                        //setFixBannerImage를 통해서 사진파일 원본자체를 useState를 통해서 저장시키고
                        //나중에 백엔드에 사진데이터를 보낼겁니다.
                        setFixBannerImage(event_data.target.files[0]);
                        //또 Filereader라는 함수를 통해
                        const reader = new FileReader();
                        //받아온 사진의 URL을 읽어서 
                        reader.readAsDataURL(event_data.target.files[0]);
                        //그리고 onloadend라고 사진파일을 다 읽었다면
                        reader.onloadend = () => {
                          //이미지 URL에 그 결과를 넣으면 실시간으로 사진이 바뀝니다.
                          setFixBannerImageURL(reader.result);
                          };
                      }
                    }} 
                  />
                  <img className="admin_ourstoryPC"
                    src={
                      //이미지URL이 비어있다면 no_image를 가져오는데
                      //no_image는 위에 import해서 가져온 사진입니다.
                      fixBannerImageURL === '' ?
                      no_image : fixBannerImageURL
                    } 
                    alt="배너이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{fixBannerImageError}</H.admin_errorBox>
              </div>
              
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>모바일용 배너이미지<span> * </span></p>
                      <p className="labelImgInfo">사이즈 630 x 600px</p>
                      <p className="labelImgInfo">모바일 가로 360 보임</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='fix_banner_image_mobile'>
                        {/* 배너 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='fix_banner_image_mobile'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      accept='image/gif, image/jpeg, image/png'
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        //자세한 내용은 위 배너이미지 추가에 넣어났습니다.
                        if(event_data.target.files[0] !== undefined){
                          setFixBannerImageMobie(event_data.target.files[0]);
                          const reader = new FileReader();
                          reader.readAsDataURL(event_data.target.files[0]);
                          reader.onloadend = () => {
                            setFixBannerImageMobieURL(reader.result);
                          };
                        }
                      }} 
                    />
                    <img className="admin_ourstoryMo"
                      src={
                        //자세한 내용은 위 배너이미지 추가에 넣어났습니다.
                        fixBannerImageMobieURL === '' ?
                        no_image : fixBannerImageMobieURL
                      } 
                      alt="배너 모바일 이미지"
                    />
                </div>
              <H.admin_errorBox className="marginLeft150">{fixBannerImageMobieError}</H.admin_errorBox>
            </div>
            </H.admin_labelImg>
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default OurStoryBannerManagement;
